import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

// console.log("processs : ",process)
const firebaseConfig = {
    // apiKey: process.env.REACT_APP_API_KEY,
    // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_DATABASE_URL,
    // projectId: process.env.REACT_APP_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_APP_ID,
    apiKey: "AIzaSyCLkEV5kB0rhbSxMG6zmt7uSb446aIoy5c",
    authDomain: "cet-mba-director-fbc8f.firebaseapp.com",
    databaseURL: "https://cet-mba-director-fbc8f-default-rtdb.firebaseio.com",
    projectId: "cet-mba-director-fbc8f",
    storageBucket: "cet-mba-director-fbc8f.appspot.com",
    messagingSenderId: "188051289749",
    appId: "1:188051289749:web:5a9759d8169967dc428d9c"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
